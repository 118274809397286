import React, { useState } from 'react';
import styles from './menuPage.module.css';
import { Key, CreditCard, MapPin, Edit, Settings, User, X, LogOut, List, Verified, VerifiedIcon } from 'lucide-react';

export default function MenuPage({showMenu ,setShowMenu ,setShowMasterListModal,showMasterListModal,logout,user}) {
    function handleMaster(){
        setShowMasterListModal(!showMasterListModal);
        setShowMenu(!showMenu)
        console.log("kkk")
    }
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerTitle}>{user.given_name}</div>
        <div><Verified/></div>
      </header>

      <div className={styles.profileInfo}>
        <div className={styles.avatar}>
          <img className={styles.avatar} src={user.picture}/>
        </div>
        <h2 className={styles.name}>{user.name}</h2>
        <p className={styles.email}>{user.email}</p>
        
        <div className={styles.iconContainer}>
          <div className={styles.icon}>
            <Key size={20} />
          </div>
          <div className={styles.icon}>
            <CreditCard size={20} />
          </div>
          <div className={styles.icon}>
            <MapPin size={20} />
          </div>
        </div>
      </div>

      {/* <div className={styles.syncCard}>
        <h3 className={styles.syncTitle}>
          Sync and personalise Chrome across your devices
        </h3>
        <button className={styles.syncButton}>
          Turn on sync...
        </button>
      </div> */}

      <ul className={styles.menuList}>
        <li className={styles.menuItem} onClick={handleMaster}>
          <List className={styles.menuIcon} />
          Master List
        </li>
        <li className={styles.menuItem}>
          <Edit className={styles.menuIcon} />
          Customise profile
        </li>
        <li className={styles.menuItem}>
          <User className={styles.menuIcon} />
          Manage your Account
        </li>
        <li className={styles.menuItem}>
          <Settings className={styles.menuIcon} />
        Settings
        </li>
        <li className={styles.menuItem} onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }>
          <LogOut className={styles.menuIcon} />
          Log out
        </li>
      </ul>
    </div>
  );
}

