import React, { useEffect } from 'react';
import styles from './payment-form.module.css';
import { CheckBoxRounded, PaymentOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { Check, TicketCheck } from 'lucide-react';

export default function PaymentForm() {
  const location = useLocation();

    // Extract query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success');
    const sessionId = searchParams.get('session_id');
    const canceled = searchParams.get('canceled');

    useEffect(() =>{
      console.log(success,sessionId);
    },[])
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Payment</h1>
      <p className={styles.subtitle}>All transactions are secure and encrypted.</p>

      
      <div style={{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        gap:"4em",
        flexDirection:"column"
      }}>
        <div><h1>Payment Status</h1></div>
            {success && (
                <div>
                    <p>Payment Successful! 🎉</p>
                    <Check />
                    {/* <p>Session ID: {sessionId}</p> */}
                    <div>Pdfing</div>
                </div>
            )}
            {canceled && <p>Payment Canceled. Please try again.</p>}
            {!success && !canceled && <p>No payment status available.</p>}
            
        
      </div>

      {/* <div className={styles.paymentMethods}>
        <div className={styles.methodCard}>
          <div className={styles.methodHeader}>
            <span className={styles.cardIcon}>□</span>
            <span>Credit card</span>
            <div className={styles.cardLogos}>
              <span className={styles.visaLogo}>VISA</span>
              <span className={styles.amexLogo}>AMEX</span>
              <span className={styles.more}>+3</span>
            </div>
          </div>

          <div className={styles.cardInputs}>
            <input
              type="text"
              placeholder="Card number"
              className={styles.fullWidth}
            />
            <div className={styles.cardDetails}>
              <input
                type="text"
                placeholder="Expiration date (MM / YY)"
                className={styles.halfWidth}
              />
              <input
                type="text"
                placeholder="Security code"
                className={styles.halfWidth}
              />
            </div>
            <input
              type="text"
              placeholder="Name on card"
              className={styles.fullWidth}
            />
          </div>
        </div>

        <div className={styles.methodOption}>
          <input type="radio" name="payment" id="paypal" />
          <label htmlFor="paypal">PayPal</label>
          <span className={styles.paypalLogo}>PayPal</span>
        </div>

        <div className={styles.methodOption}>
          <input type="radio" name="payment" id="stripe" />
          <label htmlFor="stripe">Stripe</label>
          <span className={styles.stripeLogo}>stripe</span>
        </div>
      </div>

      <div className={styles.remember}>
        <label>
          Remember me
          <input type="checkbox" />
          <span className={styles.toggle}></span>
        </label>
      </div>

      <div className={styles.phoneInput}>
        <span className={styles.phoneIcon}>□</span>
        <input type="tel" placeholder="Mobile phone number" />
      </div>

      <button className={styles.subscribeButton}>
        Subscribe now
      </button> */}
    </div>
  );
}
