// Allpagee.jsx

import React, { useEffect, useState } from "react";
import './Allpagee.css';
import Banner from "../HomePage/AllPage/Image";
import Leftside from "../Allin/Contenthead/Leftheading";
import Rightside from "../Allin/Description/Descriptionright";
import { useParams } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";


const AllPageee = ({showMasterListModal,setShowMasterListModal,isAuthenticated,setIsAuthenticated,file, setFile}) => {

  const data="Try for free"
  const [content, setContent]= useState({
    "Header": "",
    "Introduction": "",
    "Impact on Industries": "",
    "Last Block": "V"
  }
  );
  const [blog, setBlog] = useState({
    "id": 3,
    "date": "2024-09-07",
    "type": "User",
    "author": "Jhon Russel",
    "author_pic": "https://cdn.profoto.com/cdn/053149e/contentassets/d39349344d004f9b8963df1551f24bf4/profoto-albert-watson-steve-jobs-pinned-image-original.jpg?width=1280&quality=75&format=jpg",
    "images": [
        "A",
        "B",
        "C"
    ],
    "content": ""
});

const [isLoded, setIsLoded]=useState(false)
async function handleThisThing(){  
    console.log("its Cliked 5",id)
        try {
           const response = await fetch(`https://pdfing.ai:7000/vlogs/${id}`);
           const data = await response.json();
           
           if (response.status == 404){
            alert(response.status, "is the status blog not found");
            return
           }
           console.log(data)
           setContent(data.content)
           setBlog(data) 
           setIsLoded(true)
        } catch (error) {
            console.log("Error fetching data:", error);
        }
}
  
  const { id } = useParams();
  useEffect (()=>{
    handleThisThing()
  }, []);

  return (
    <>{isLoded && <div className="Home">
      {/* <Navbar showMasterListModal={showMasterListModal} setShowMasterListModal={setShowMasterListModal} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated}  data={data} file={file} setFile={setFile}/> */}
      <Banner blog={blog}/>
      <div className="all-page-container">
        <div className="leftside">
          <Leftside authorImage={blog.author_pic} blog={blog}/>
          {/* <Leftside authorImage="swsw"/> */}
        </div>
        <div id="descriptionId" className="rightside">
          <Rightside blog={blog}/>
        </div>
      </div>
    </div>}</>
  );
};

export default AllPageee;
