import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Button, IconButton, Tooltip } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import zIndex from '@mui/material/styles/zIndex';
import jsPDF from 'jspdf';

import html2pdf from "html2pdf.js";



const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const ShareableLink = styled.a`
  margin-bottom: 10px;
  color: #007bff;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const CopyButton = styled.button`
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;


const customStyles = {
  content: {
    width: '500px',
    height: '220px',
    margin: 'auto',
    left: '-120px',
    
  },
};

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  region: 'eu-north-1',
});

//const S3_BUCKET = 'parvbucket12';
const S3_BUCKET = 'pdfing.ai';



export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: 'eu-north-1',
});


function ShareLinkComponent({shareableLink, setShareableLink, modalOpen, setModalOpen, response, fileName, file }) {


  const generateTimestamp = () => {
    const now = new Date();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${month}-${day}_${hours}-${minutes}-${seconds}`;
  };



  const wrapHtmlContent = (htmlContent) => {
    return `
          <!DOCTYPE html>
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
        `;
  };

  // const shortenUrl = async (longUrl) => {
  //   try {
  //     console.log("in shortner:", longUrl);
  //     const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  //     const apiUrl = `https://ulvis.net/api.php?url=${encodeURIComponent(longUrl)}`;
  //     const response = await fetch(proxyUrl + apiUrl);
  //     const shortUrl = await response.text(); // Get the short URL as a plain text response
  //     console.log("shortended: ",shortUrl)
  //     return shortUrl;
  //   } catch (error) {
  //     console.error('Error shortening URL:', error);
  //     return null;
  //   }
  // };
  // const createPdfFile = (content, fileName = `${generateTimestamp()}.pdf`) => {
  //   return new Promise((resolve, reject) => {
  //     // Create a hidden container to render the HTML content
  //     const container = document.createElement('div');
  //     container.innerHTML = content;
  //     document.body.appendChild(container); // Temporarily append the container to the body for rendering
  
  //     // Use html2pdf.js to convert the HTML content into a PDF
  //     const options = {
  //       margin: 10,
  //       filename: fileName,
  //       image: { type: 'jpeg', quality: 0.98 },
  //       html2canvas: { scale: 2 },
  //       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //     };
  
  //     html2pdf()
  //       .from(container)
  //       .set(options)
  //       .get('pdf')
  //       .then(pdf => {
  //         if (pdf) {
  //           // Convert jsPDF output to Blob
  //           const pdfBlob = pdf.output('blob');
  //           // Create a File object from the Blob
  //           const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
  
  //           // Resolve the promise with the PDF file
  //           resolve(pdfFile);
  //         } else {
  //           reject('Failed to generate PDF.');
  //         }
  //       })
  //       .catch(err => {
  //         // Reject the promise if an error occurs
  //         reject(err);
  //       })
  //       .finally(() => {
  //         // Clean up by removing the container from the DOM
  //         document.body.removeChild(container);
  //       });
  //   });
  // };
  

  const handleDownloadDOC = async () => {
    console.log(wrapHtmlContent(response), "typefile");

    const wrappedHtml = wrapHtmlContent(response);
    if (!wrappedHtml) {
        console.error("Wrapped HTML content is empty.");
        return;
    }

    // Create a temporary container to render the HTML
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = wrappedHtml;

    // Options for html2pdf
    const options = {
        margin: 1,
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    try {
        // Generate PDF as a Blob
        const pdfBlob = await html2pdf()
            .set(options)
            .from(tempContainer)
            .outputPdf('blob'); // This returns a Blob

        console.log("Generated PDF Blob:", pdfBlob);

        // Prepare data for API upload
        const apiEndpoint = "https://pdfing.ai:7000/upload-img";
        const formData = new FormData();
        formData.append("file", pdfBlob);
        formData.append("filename", `${fileName}_Overview.pdf`);

        // Make the API call
        const response = await fetch(apiEndpoint, {
            method: "POST",
            body: formData,
        });

        // Check response status
        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        // Parse and handle the response
        const data = await response.json();
        console.log("Upload successful. Response:", data);

        // Set the shareable link
        setShareableLink(data.file_url);
    } catch (error) {
        // Handle errors
        console.error("Error during the process:", error);
    }

    // Open the modal
    setModalOpen(true);
};



  const uploadToS3 = async (file, filename) => {

    console.log("file name-----", filename)
    if (!file) return;



    const params = {
      Bucket: S3_BUCKET,
      Key: filename,
      Body: file,
      ACL: 'public-read',
      ContentType: 'application/msword',
    };


    return new Promise((resolve, reject) => {
      s3.upload(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve(data.Location);
        }
      });
    });
  };



  return (
    <div>
      <Tooltip title="Share">
        <IconButton onClick={() => handleDownloadDOC()} ><ShareIcon /></IconButton>
      </Tooltip>
      {shareableLink && (
        <Modal style={customStyles} isOpen={modalOpen} onRequestClose={() => setModalOpen(false)}>
          <ModalWrapper>
            <h2 style={{ marginBottom: "10px" }} >Shareable Link:</h2>
            <ShareableLink href={shareableLink} target="_blank" rel="noopener noreferrer">
              {shareableLink}
            </ShareableLink>
            <CopyButton onClick={() => navigator.clipboard.writeText(shareableLink)}>
              Copy Link
            </CopyButton>
          </ModalWrapper>
        </Modal>
      )}
    </div>
  )
}

export default ShareLinkComponent
