import { useState } from 'react'
import styles from './QuestionList.module.css'
import AddItemForm from './AddItemForm'

export default function QuestionList({onEditQuestion,topicIndex, questions, onDeleteQuestion }) {
  const [editingQuestion, setEditingQuestion] = useState(null)

  const startEditingQuestion = (index) => {
    setEditingQuestion(index)
  }
  const handleEditQuestion = (index, newName) => {
    onEditQuestion(topicIndex,index, newName)
    setEditingQuestion(null)
  }
  return (
    <ul className={styles.questionList}>
      {questions.map((question, index) => (
        <li key={index} className={styles.questionItem}>
          {editingQuestion === index ? (
              <AddItemForm
                onAdd={(newName) => handleEditQuestion(index, newName)}
                placeholder="Edit topic"
                initialValue={question.question}
              />
            ) : (
              <span>
                {/* {question.question}{" (TopicIndex: " + topicIndex + " & questionIndex: " + index + " & questionId: " + question.question_id + ")"} */}
                {question.question}
              </span>
            )}
          <div className={styles.topicActions}>
          <button className={styles.actionButton} onClick={() => startEditingQuestion(index)}>
                Edit
              </button>
          <button className={styles.deleteButton} onClick={() => onDeleteQuestion(index)}>Delete</button>
          </div>
        </li>
      ))}
    </ul>
  )
}



