import { useState, useEffect } from 'react'
import styles from './AddItemForm.module.css'

export default function AddItemForm({ onAdd, placeholder, initialValue = '' }) {
  const [input, setInput] = useState(initialValue)

  useEffect(() => {
    setInput(initialValue)
  }, [initialValue])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (input.trim()) {
      onAdd(input.trim())
      setInput('')
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        placeholder={placeholder}
        className={styles.input}
      />
      <button type="submit" className={styles.button}>{initialValue ? 'Update' : 'Add'}</button>
    </form>
  )
}

