import React, { useEffect, useState } from "react";
// import { GoogleLogin } from "@react-oauth/google";
import styles from "./Login.module.css";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";

const Login = ({ isAuthenticated, setIsAuthenticated, simpleAuth, setSimpleAuth }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!password) {
            setError("Both email and password are required.");
            return;
        }

        if ( password === "pdfing321") {
            // localStorage.setItem("userID", email);
            localStorage.setItem("userPassword", password);
            setSimpleAuth(true);

            localStorage.setItem("authToken", "1");
            navigate("/");
        } else {
            setError("Invalid email or password. Please try again.");
        }
    };

    const handleLoginSuccess = (credentialResponse) => {
        try {
            const decoded = jwtDecode(credentialResponse?.credential);
            const { name, email, picture } = decoded;

            console.log("User Details:", { name, email, picture });
            localStorage.setItem("authTokenGoogle", credentialResponse?.credential);
            setIsAuthenticated(true);
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    };

    const handleLoginFailure = () => {
        console.error("Login Failed");
    };

    useEffect(() => {
        const storedId = localStorage.getItem("userID");
        const encryptedPassword = localStorage.getItem("userPassword");

        if (storedId && encryptedPassword) {
            setEmail(storedId);
            setPassword(encryptedPassword);
        }
    }, [setEmail, setPassword]);

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <h2 className={styles.title}>Welcome to My App</h2>
                {error && <div className={styles.error}>{error}</div>}
                <p className={styles.subtitle}>Please log in to continue</p>

                <form className={styles.form} onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                        {/* <label className={styles.label} htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            className={styles.input}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        /> */}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.label} htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            className={styles.input}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className={styles.button}>Login</button>
                </form>

                {/* <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginFailure}
                /> */}
            </div>
        </div>
    );
};

export default Login;

{/* <GoogleLogin
          buttonText="Sign in with Google"
          onSuccess={handleLoginSuccess}
          onFailure={handleLoginFailure}
          cookiePolicy={'single_host_origin'}
          className={styles.googleButton}
        /> */}

// import React from 'react'

// export default function Login() {
//   return (
//     <div>Login</div>
//   )
// }
