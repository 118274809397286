export const termsData = [{
    lastUpdated: `October 12th 2023`,
    text: `Thank you for choosing Pdfing. Your privacy is critically important to us. This Privacy Policy outlines our practices for collecting, using, and safeguarding your information when you use our services, products, websites, and applications (collectively, the “Services”). By using Pdfing Services, you agree to the privacy terms outlined below.`,
},
{
    heading: ``,
    text: `<strong>IMPORTANT NOTICE REGARDING ARBITRATION FOR U.S. CUSTOMERS: WHEN YOU AGREE TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION) TO RESOLVE ANY DISPUTE BETWEEN YOU AND PDFing.ai THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN COURT. PLEASE REVIEW CAREFULLY SECTION 16 \"DISPUTE RESOLUTION\" BELOW FOR DETAILS REGARDING ARBITRATION.`,
},
{
    text: ` <strong>1.Information We Collect .</strong> We collect information in the following ways: `
},
{
    text: `<strong>a. Information You Provide Directly </strong> Account Information: When you register, we may collect your name, email address, password, and other personal details necessary to provide you with a personalized experience. 

Payment Information: If you make a purchase, we may collect your billing information, payment card details, and other information necessary to complete transactions. 

Support Interactions: If you contact us for customer support, we may collect details about your request to provide you with the best assistance. `
},
{
    text: `<strong>b. Information Collected Automatically </strong> Device Information: We collect information about the device you use to access our Services, such as the type of device, operating system, and browser type. 

Usage Data: We collect data about how you interact with our Services, including logs of when you access our Services, the features you use, and actions you take. 

Cookies and Tracking Technologies: We may use cookies and similar technologies to track your activity on our website and within our apps. This information is used to improve your experience and analyze usage patterns. `
},
{
    heading: `<strong>c. Information from Third Parties</strong>`,
    text: `We may receive information from third parties, such as marketing partners, analytics providers, or social media platforms, to enhance our Services and provide relevant information or advertisements. `
},
{
    text: `<strong>2. How We Use Your Information </strong>We use the information we collect for various purposes, including to: </br>

- Provide and maintain our Services; </br>

- Process and complete transactions, and send related information, including purchase confirmations and invoices; </br>

- Manage your account and respond to inquiries; </br>

- Improve and personalize your experience with our Services; </br>

- Conduct research and analysis to develop and improve our Services; </br>

- Send you information about new features, offers, and updates, with your consent where required; </br>

- Detect, investigate, and prevent fraudulent transactions, abuse, or security threats;</br>

- Comply with legal obligations and enforce our terms. `
},
{
    text: `<strong>3. How We Share Your Information </strong>We may share your information in the following cases:</br> 

 

- With Service Providers: We may share your information with third-party service providers who assist us in delivering, improving, and supporting our Services. </br> 

- With Business Partners: We may share aggregated, anonymized data with our business partners to enhance and analyze the performance of our Services. </br> 

- For Legal Reasons: We may disclose your information if required by law or in response to a valid legal process, such as a subpoena, court order, or government demand. </br> 

- In Business Transfers: If we are involved in a merger, acquisition, or sale of assets, your information may be transferred to the successor company, subject to this Privacy Policy. </br> 

 

Sale of Personal Information </br> 

Under certain circumstances, Pdfing may sell personal information to third parties, such as marketing partners, to enhance our ability to provide relevant offerings and promotions to our users. If we sell your personal information, we will provide you with an option to opt-out of the sale of your personal information, in accordance with applicable data privacy laws.  </br> 

 

You may exercise your right to opt out of the sale of your personal information by contacting us at [contact@Pdfing.com] or following instructions provided in the relevant communications. </br> `
},
{
    text: `<strong>4. Your Choices and Rights</strong>     Depending on your location, you may have certain rights regarding your personal information. These rights may include: </br>

- Access, correction, or deletion of your personal information; </br>

- Restriction or objection to processing your information; </br>

- Data portability; </br>

- Withdrawing your consent at any time;</br>

- Lodging a complaint with a supervisory authority. </br>

 

To exercise these rights, please contact us at [contact@Pdfing.com]. We will respond to your request in accordance with applicable laws. </br>`
},
{
    text: `<strong>5. Security of Your Information</strong> We implement reasonable security measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no security system is completely secure, and we cannot guarantee the absolute security of your information. `
},
{
    text: `<strong> 6. Data Retention </strong> We retain your information only as long as necessary for the purposes outlined in this Privacy Policy or as required by law. When we no longer need your information, we will securely delete or anonymize it. `
},
{
    text: `<strong>7. International Data Transfers </strong>Your information may be transferred to and processed in countries outside your own, including countries with data protection laws different from your own. We take appropriate steps to ensure your information remains protected according to this Privacy Policy. `
},
{
    text:`<strong>8. Children’s Privacy </strong> Our Services are not directed toward children under 13, and we do not knowingly collect personal information from children. If we discover that a child has provided us with personal information, we will promptly delete it. `
},
{
    text: `<strong>9. Arbitration Clause </strong> By using Pdfing Services, you agree that any dispute, claim, or controversy arising out of or related to this Privacy Policy, including the breach, termination, enforcement, interpretation, or validity thereof, shall be settled by binding arbitration administered by JAMS or NAM in New York City, New York. The arbitration will be conducted in English and in accordance with the rules of the selected arbitration company (JAMS or NAM).  </br>

 

The arbitration decision shall be final and binding upon the parties, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction. This arbitration clause does not apply to claims for injunctive or equitable relief, which may be brought in a court of competent jurisdiction. `
},
{
    text: `<strong>10. Limitation of Liability </strong> To the fullest extent permitted by law, Pdfing’s liability for any damages arising from or related to your use of the Services, regardless of the form of action, will at all times be limited to $100 or the amount you have paid to Pdfing for the use of Services in the three (3) months preceding the event giving rise to the claim, whichever is less.  </br>

 

Pdfing shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or goodwill, even if Pdfing has been advised of the possibility of such damages. `
},
{
    text: `<strong>11. Changes to this Privacy Policy </strong>We may update this Privacy Policy periodically to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of significant changes through our Services or other means, and we encourage you to review this policy periodically. `
},
{
    text: `<strong>12. Contact Us </strong> </br>If you have questions or concerns about this Privacy Policy or our data practices, please contact us at: </br>
    <strong>Pdfing  </br>
4815 Ave N </br>
Brooklyn, NY 11234 </br>
Email: info@pdfing.ai  </br> 
Phone: [Your Company Phone Number] </br></strong>`
},

]




export const jsonData = [{
    category: "Necessary (30)",
    description: "Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.",
    columns: [
        "Name",
        "Provider",
        "Purpose",
        "Maximum Storage Duration",
        "Type"
    ],
    rows: [
        {
            Name: "__cf_bm [x2]",
            Provider: "verisoul.ai, Vimeo",
            Purpose: "This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.",
            "Maximum Storage Duration": "1 day",
            Type: "HTTP Cookie"
        },
        {
            Name: "__mp_opt_in_out_#",
            Provider: "scribehow.com",
            Purpose: "Stores the user's cookie consent state for the current domain",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        },
        {
            Name: "__Secure-3PAPISID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "__Secure-3PSID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "__Secure-3PSIDCC",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "1 year",
            Type: "HTTP Cookie"
        },
        {
            Name: "_cfuvid",
            Provider: "Vimeo",
            Purpose: "This cookie is a part of the services provided by Cloudflare - Including load-balancing, deliverance of website content and serving DNS connection for website operators.",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        },
        {
            Name: "_gid",
            Provider: "Google",
            Purpose: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
            "Maximum Storage Duration": "1 day",
            Type: "HTTP Cookie"
        },
        {
            Name: "_gf",
            Provider: "Cookiebot",
            Purpose: "Used to count the number of sessions to the website, necessary for optimizing CMP provider delivery.",
            "Maximum Storage Duration": "Session",
            Type: "Pixel Tracker"
        },
        {
            Name: "_JAR",
            Provider: "Google",
            Purpose: "Collects information on user behaviour on multiple websites. This information is used in order to optimize the relevance of dvertisements on the website.",
            "Maximum Storage Duration": "30 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "APISID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "AWSALB",
            Provider: "api.sirv.com",
            Purpose: "Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user xperience.",
            "Maximum Storage Duration": "7 days",
            "Typ": "HTTP Cookie"
        },
        {
            Name: "AWSALBCORS [x2]",
            Provider: "api.sirv.com, cdn.sirv.com",
            Purpose: "Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user xperience.",
            "Maximum Storage Duration": "7 days",
            "Typ": "HTTP Cookie"
        },
        {
            Name: "CookieConsent [x2]",
            Provider: "Cookiebot",
            Purpose: "Stores the user's cookie consent state for the current domain",
            "Maximum Storage Duration": "120 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "cookietest",
            Provider: "js.sirv.com",
            Purpose: "This cookie is used to determine if the visitor has accepted the cookies.",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        },
        {
            Name: "datadome",
            Provider: "dd.PDFing.ai",
            Purpose: "Used in context with the website’s BotManager. The BotManager detects and mitigates bots trying to access the website.",
            "Maximum Storage Duration": "1 year",
            Type: "HTTP Cookie"
        },
        {
            Name: "HSID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "_lipt",
            Provider: "LinkedIn",
            Purpose: "Stores the user's cookie consent state for the current domain",
            "Maximum Storage Duration": "180 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "NID",
            Provider: "Google",
            Purpose: "Registers a unique ID that identifies a returning user's device. The ID is used for targeted ads.",
            "Maximum Storage Duration": "180 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "objectId@0.0#.#.#.##@#.##",
            Provider: "embed.sequoi.io",
            Purpose: "Holds the user's timezone.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTTP Cookie"
        },
        {
            Name: "omSessionId",
            Provider: "OptinMonster",
            Purpose: "Saves the user's screen size in order to adjust the size of images on the website.",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        },
        {
            Name: "persist:root",
            Provider: "scribehow.com",
            Purpose: "This cookie stores visitor credentials in an encrypted cookie to allow the visitor to stay logged in between visits, if the isitor has accepted the 'Stay logged in' button.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTTP Cookie"
        },
        {
            Name: "SAPISID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "sentry.offlineRequest",
            Provider: "cdn.sentry.io",
            Purpose: "Detects and logs potential errors on third-party provided functions on the website.",
            "Maximum Storage Duration": "Session",
            Type: "IndexedDB"
        },
        {
            Name: "SID",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "SIDCC",
            Provider: "Google",
            Purpose: "Pending",
            "Maximum Storage Duration": "1 year",
            Type: "HTTP Cookie"
        },
        {
            Name: "SSID",
            Provider: "Google",
            Purpose: "Registers a unique ID that is used by Google to keep statistics of how the visitor uses YouTube videos across different websites.",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "wf_auth_page",
            Provider: "www.PDFing.ai",
            Purpose: "This cookie is necessary for the login function on the website.",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        }
    ]
},
{
    category: "Preferences (7)",
    description: "Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.",
    columns: [
        "Name",
        "Provider",
        "Purpose",
        "Maximum Storage Duration",
        "Type"
    ],
    "rows": [
        {
            Name: "firebaseLocalStoragedb#firebaseLocalStorage",
            Provider: "Google",
            Purpose: "Facilitates the notification function within the chatbox, allowing the website’s support team to notify the user, when a reply has been given in the chatbox.",
            "Maximum Storage Duration": "Persistent",
            Type: "IndexedDB"
        },
        {
            Name: "intercom.intercom-state [x2]",
            Provider: "app.PDFing.ai",
            Purpose: "Stores a unique ID string for each chat-box session. This allows the website-support to see previous issues and reconnect with the previous supporter.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
        },
        {
            Name: "intercom-device-id-#",
            Provider: "Intercom",
            Purpose: "Sets a specific ID for the user which ensures the integrity of the website’s chat function.",
            "Maximum Storage Duration": "271 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "intercom-id-#",
            Provider: "Intercom",
            Purpose: "Allows the website to recognise the visitor, in order to optimize the chat-box functionality.",
            "Maximum Storage Duration": "271 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "intercom-session-#",
            Provider: "Intercom",
            Purpose: "Sets a specific ID for the user which ensures the integrity of the website’s chat function.",
            "Maximum Storage Duration": "7 days",
            Type: "HTTP Cookie"
        },
        {
            Name: "lidc",
            Provider: "LinkedIn",
            Purpose: "Registers which server-cluster is serving the visitor. This is used in context with load balancing, in order to optimize user experience.",
            "Maximum Storage Duration": "1 day",
            Type: "HTTP Cookie"
        }
    ]
},
{
    category: "Statistics (20)",
    description: "Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.",
    columns: [
        "Name",
        "Provider",
        "Purpose",
        "Maximum Storage Duration",
        "Type"
    ],
    "rows": [
        {
            Name: "_ga",
            Provider: "Google",
            Purpose: "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "_ga_#",
            Provider: "Google",
            Purpose: "Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "_gat",
            Provider: "Google",
            Purpose: "Used by Google Analytics to throttle request rate.",
            "Maximum Storage Duration": "1 day",
            Type: "HTTP Cookie"
        },
        {
            Name: "_omappvp",
            Provider: "OptinMonster",
            Purpose: "This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.",
            "Maximum Storage Duration": "11 years",
            Type: "HTTP Cookie"
        },
        {
            Name: "_omappvs",
            Provider: "OptinMonster",
            Purpose: "This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.",
            "Maximum Storage Duration": "1 day",
            Type: "HTTP Cookie"
        },
        {
            Name: "_tdtest_f[x2]",
            Provider: "embed.sequel.io",
            Purpose: "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
            "Maximum Storage Duration": "Session",
            Type: "HTTP Cookie"
        },
        {
            Name: "ajs_anonymous_id[x2]",
            Provider: "Segment cdn.jsdeliver.net",
            Purpose: "This cookie is used to count how many times a website has been visited by different visitors - this is done by assigning the visitor an ID, so the visitor does not get registered twice.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
        },
        {
            Name: "ajs_anonymous_id[x2]",
            Provider: "Segment demo.arcade.software",
            Purpose: "This cookie is used to identify a specific visitor - this information is used to identify the number of specific visitors on a website.",
            "Maximum Storage Duration": "1 year",
            Type: "HTML Local Storage"
        },
        {
            Name: "amp_#",
            Provider: "embed.sequel.io",
            Purpose: "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
        },
        {
            Name: "amplitude_unsent_#",
            Provider: "embed.sequel.io",
            Purpose: "Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
          },
          {
            Name: "amplitude_unsent_identify_#",
            Provider: "embed.sequel.io",
            Purpose: "Registers data on visitors' website-behaviour. This is used for internal analysis and website optimization.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
          },
          {
            Name: "collect",
            Provider: "Google",
            Purpose: "Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.",
            "Maximum Storage Duration": "Session",
            Type: "Pixel Tracker"
          },
          {
            Name: "omLastSeen",
            Provider: "OptinMonster",
            Purpose: "This cookie is used to determine if the visitor has visited the website before, or if it is a new visitor on the website.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
          },
          {
            Name: "omSessionPageviews",
            Provider: "OptinMonster",
            Purpose: "This cookie is used to identify the frequency of visits and how long the visitor is on the website. The cookie is also used to determine how many and which subpages the visitor visits on a website – this information can be used by the website to optimize the domain and its subpages.",
            "Maximum Storage Duration": "Session",
            Type: "HTML Local Storage"
          },
          {
            Name: "omSessionStart",
            Provider: "OptinMonster",
            Purpose: "Sets a timestamp for when the visitor entered the website. This is used for analytical purposes on the website.",
            "Maximum Storage Duration": "Session",
            Type: "HTML Local Storage"
          },
          {
            Name: "omVisitsFirst",
            Provider: "OptinMonster",
            Purpose: "This cookie is used to count how many times a website has been visited by different visitors - this is done by assigning the visitor an ID, so the visitor does not get registered twice.",
            "Maximum Storage Duration": "Persistent",
            Type: "HTML Local Storage"
          },
          {
            Name: "vuid",
            Provider: "Vimeo",
            Purpose: "Collects data on the user's visits to the website, such as which pages have been read.",
            "Maximum Storage Duration": "2 years",
            Type: "HTTP Cookie"
          }
    ]
},
{
    category: "Marketing (40)",
    description: "Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.",
    columns: [
      "Name",
      "Provider",
      "Purpose",
      "Maximum Storage Duration",
      "Type"
    ],
    "rows": [
      {
        Name: "#-#",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "_mpq_f_#",
        Provider: "scribehow.com",
        Purpose: "Collects data on user behaviour and interaction in order to optimize the website and make advertisement on the website more relevant.",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "_td[_x3]",
        Provider: "demo.arcade.software, Segment",
        Purpose: "Used to track visitors on multiple websites, in order to present relevant advertisement based on the visitor's preferences.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "_gcl_au",
        Provider: "Google",
        Purpose: "Used by Google AdSense for experimenting with advertisement efficiency across websites using their services.",
        "Maximum Storage Duration": "3 months",
        Type: "HTTP Cookie"
      },
      {
        Name: "-6248cbc39a7c7",
        Provider: "Pending",
        Purpose: "N/A",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "ajs_user_id [x2]",
        Provider: "Segment cdn.jsdeliver.net",
        Purpose: "Collects data on visitors' preferences and behaviour on the website - This information is used make content and advertisement more relevant to the specific visitor.",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "ajs_user_id [x2]",
        Provider: "demo.arcade.software",
        Purpose: "This cookie is used to collect data on the visitor's behavior on the website - this information can be used to assign the visitor to a visitor segment, based on common preferences.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "bcookie",
        Provider: "LinkedIn",
        Purpose: "Used by the social networking service, LinkedIn, for tracking the use of embedded services.",
        "Maximum Storage Duration": "1 year",
        Type: "HTTP Cookie"
      },
      {
        Name: "IDE",
        Provider: "Google",
        Purpose: "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking one of the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
        "Maximum Storage Duration": "400 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "iJ5q-lO9@$",
        Provider: "YouTube",
        Purpose: "Registers a unique ID to keep statistics of what videos from YouTube the user has seen.",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "LAST_RESULT_ENTRY_KEY",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "LogsDatabaseV2V#|LogsRequestsStore",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Persistent",
        Type: "IndexedDB"
      },
      {
        Name: "mp_#_mixpanel",
        Provider: "scribehow.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "1 year",
        Type: "HTTP Cookie"
      },
      {
        Name: "nextId",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "omFoldersSeen",
        Provider: "OptinMonster",
        Purpose: "Used in context with the website's pop-up questionnaires and messengering. The data is used for statistical or marketing purposes.",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "pagead/1p-conversion/#",
        Provider: "Google",
        Purpose: "Pending",
        "Maximum Storage Duration": "Session",
        Type: "Pixel Tracker"
      },
      {
        Name: "pagead/1p-user-list/#",
        Provider: "Google",
        Purpose: "Tracks if the user has shown interest in specific products or events across multiple websites and detects how the user navigates between sites. This is used for measurement of advertisement efforts and facilitates payment of referral-fees between websites.",
        "Maximum Storage Duration": "Session",
        Type: "Pixel Tracker"
      },
      {
        Name: "pagead/landing",
        Provider: "Google",
        Purpose: "Collects data on visitor behaviour from multiple websites, in order to present more relevant advertisement - This also allows the website to limit the number of times that they are shown the same advertisement.",
        "Maximum Storage Duration": "Session",
        Type: "Pixel Tracker"
      },
      {
        Name: "pardot",
        Provider: "go.PDFing.ai",
        Purpose: "Used in content with Account-Based-Marketing (ABM). The cookie registers data such as IP addresses, time spent on the website and page requests for the visit. This is used for retargeting of multiple users coming from the same IP addresses. ABM usually facilitates B2B marketing purposes.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "remote_sid",
        Provider: "YouTube",
        Purpose: "Necessary for the implementation and functionality of YouTube video-content on the website.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "requests",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "ServiceWorkerLogsDatabaseV5WHealthLog",
        Provider: "YouTube",
        Purpose: "Necessary for the implementation and functionality of YouTube video-content on the website.",
        "Maximum Storage Duration": "Persistent",
        Type: "IndexedDB"
      },
      {
        Name: "test_cookie",
        Provider: "Google",
        Purpose: "Used to check if the user's browser supports cookies.",
        "Maximum Storage Duration": "1 day",
        Type: "HTTP Cookie"
      },
      {
        Name: "TESTCOOKIESSENAB",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "1 day",
        Type: "HTTP Cookie"
      },
      {
        Name: "VISITOR_INFO1_LIVE",
        Provider: "YouTube",
        Purpose: "Tries to estimate the users' bandwidth on pages with integrated YouTube videos.",
        "Maximum Storage Duration": "180 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "YSC",
        Provider: "YouTube",
        Purpose: "Registers a unique ID to keep statistics of what videos from YouTube the user has seen.",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "yt-remote-device-id",
        Provider: "YouTube",
        Purpose: "Registers a unique ID to keep statistics of what videos from YouTube the user has seen.",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-player-bandwidth",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video.",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "YtdbMetadatabase",
        Provider: "YouTube",
        Purpose: "Used to track user's interaction with embedded content.",
        "Maximum Storage Duration": "Persistent",
        Type: "IndexedDB"
      },
      {
        Name: "yt-remote-cast-available",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video.",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-cast-installed",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video.",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-connected-devices",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-device-id",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-fast-check-period",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-session-app",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "yt-remote-session-name",
        Provider: "YouTube",
        Purpose: "Stores the user's video player preferences using embedded YouTube video",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      }
    ]
  },
  {
    category: "Unclassified (16)",
    description: "Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.",
    columns: [
        "Name",
        "Provider",
        "Purpose",
        "Maximum Storage Duration",
        "Type"
    ],
    "rows": [
      {
        Name: "AWSALBCORS",
        Provider: "cdn-apac01.me",
        Purpose: "Pending",
        "Maximum Storage Duration": "7 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "AWSALB",
        Provider: "cdn-apac01.me",
        Purpose: "Pending",
        "Maximum Storage Duration": "7 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "ajs_anonymous_id",
        Provider: "app.PDFing.ai",
        Purpose: "Pending",
        "Maximum Storage Duration": "1 year",
        Type: "HTML Local Storage"
      },
      {
        Name: "ajs_user_id",
        Provider: "app.PDFing.ai",
        Purpose: "Pending",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "amplitude_id_*",
        Provider: "app.PDFing.ai",
        Purpose: "Pending",
        "Maximum Storage Duration": "Session",
        Type: "HTML Local Storage"
      },
      {
        Name: "AWSALB",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "7 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "AWSALBCORS",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "7 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "http_referrer",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "pf_pf_*",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "10 years",
        Type: "HTTP Cookie"
      },
      {
        Name: "pf_pf_*",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "24 days",
        Type: "HTTP Cookie"
      },
      {
        Name: "pf_pf_*",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "2 months",
        Type: "HTTP Cookie"
      },
      {
        Name: "pf_pf_*",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "1 month",
        Type: "HTTP Cookie"
      },
      {
        Name: "pf_pf_*",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "pf_pf_*",
        Provider: "app.PDFing.ai",
        Purpose: "Pending",
        "Maximum Storage Duration": "Session",
        Type: "HTTP Cookie"
      },
      {
        Name: "user_uuid",
        Provider: "api.pathfactory.com",
        Purpose: "Pending",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      },
      {
        Name: "vt_data",
        Provider: "app.PDFing.ai",
        Purpose: "Pending",
        "Maximum Storage Duration": "Persistent",
        Type: "HTML Local Storage"
      }
    ]
  }

]