import { Button, Card, Switch } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import "./SummaryChatbot.css";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { jsPDF } from 'jspdf';
import { AddCard, AddCardRounded, AddCircleOutline, AttachFile, BlindsClosedRounded, ClosedCaptionRounded, CloseFullscreenOutlined, CloseFullscreenRounded, CloseOutlined, CloseRounded, FileUpload, FileUploadRounded, FileUploadTwoTone, MicNone, RecordVoiceOverOutlined, UploadFileOutlined, VoiceChatOutlined, VoicemailSharp, VoiceOverOffOutlined } from '@mui/icons-material';
import AudioRecorder from "./WhisperAi.js"
import pdfToText from "react-pdftotext";
import { Download, Share, Share2Icon } from 'lucide-react';
import html2pdf from "html2pdf.js";


function SummaryChatbot({shareableLink, setShareableLink, modalOpen, setModalOpen,showSummaryChatbot, response, userFile, setUserFile, isSelectedMath, setChatHistory, chatHistory, fileContent, fileName, fileUrl, setFileUrl }) {



  const [chatMessages, setChatMessages] = useState([]);
  const [userUploadedContent, setUserUploadedContent] = useState('');

  const [userMessage, setUserMessage] = useState();
  const [userWebUrls, setUserWebUrls] = useState([]);
  const [websearch, setWebsearch] = useState(false);
  const [mapSearch, setMapSearch] = useState(false);
  const [showMoreFeature, setShowMoreFeature] = useState(false);


  const chatboxRef = useRef(null);
  const chatInputRef = useRef(null);
  const cardRef = useRef(null);

  const headerRef = useRef(null);
  const [isLoading, setIsloading] = useState(false);
  const [response2, setResponse2] = useState();
  const [chatInput, setChatInput] = useState('');
  const fileInputRef = useRef(null);



  // useEffect(() => {
  //   if (chatboxRef.current) {
  //     const { scrollHeight, clientHeight } = chatboxRef.current;
  //     const scrollPosition = scrollHeight - clientHeight - cardRef?.current?.clientHeight + 200; 
  //     chatboxRef.current.scrollTo(0, scrollPosition);
  //   }
  // }, [chatHistory]);
  useEffect(() => {
    console.log("answer3", response)
    let upscroll = 0;
    if (cardRef.current && headerRef.current) {
      upscroll = cardRef.current.clientHeight + headerRef.current.clientHeight;
      console.log("Cardref:", cardRef.current.clientHeight);
    }
    if (chatboxRef.current) {
      const { scrollHeight, clientHeight } = chatboxRef.current;
      const scrollPosition = scrollHeight;
      chatboxRef.current.scrollTo({
        top: scrollPosition - upscroll,
        behavior: 'smooth',
      });
      console.log("it runs scrollPosition", scrollPosition, clientHeight)
    }
  }, [chatHistory]);

  useEffect(() => {
    console.log("0000")
    console.log("userFile:", userFile);
    console.log("response:ss", response);
    const processFile = async () => {
      if (userFile && userFile.type === "application/pdf") {
        console.log("111")
        try {
          const text = await extractTextFromPDF(userFile);
          console.log("3333", text);
          setUserUploadedContent(text);
          if (fileContent){
            fileapicall(text);
          }
        } catch (error) {
          if (error.message === "Failed to fetch") {
            alert("Internet connection is lost. Please check your network.")
          } else {
            alert("An unexpected error occurred.");
          }
          console.error("Error extracting text from PDF:", error);
        }
      } else {
        console.error("Please upload a valid PDF file.");
      }
    };

    processFile(); // Call the async function
  }, [showSummaryChatbot]); // Add userFile as a dependency



  const handleInputChange = (event) => {
    setChatInput(event.target.value);
    setUserMessage(event.target.value);
  };
  const handleInputUrlsChange = (event) => {
    setUserWebUrls(event.target.value.split(", ").map(item => item.trim()));

  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      apicall();
    }
  };
  const handleKeyUrlDown = (event) => {
    if (event.key === 'Enter') {
      console.log(userWebUrls)
    }
  };
  const extractTextFromPDF = async (file) => {
    try {
      const text = await pdfToText(file); // file should be a valid File object (from input)
      return text;
    } catch (error) {
      console.error("Error extracting text from PDF:", error);
    }
  };

  const handleFileChange = async (event) => {
    console.log("file uploaded", event.target.files[0])

    const file = event.target.files[0];
    setUserFile(event.target.files[0])
    if (file && file.type === 'application/pdf') {
      const text = await extractTextFromPDF(file);
      console.log("Extracted text:", text);
      setUserUploadedContent(text);
      if (fileContent){
        fileapicall(text);
      }
    } else {
      console.error("Please upload a valid PDF file.");
    }
    event.target.value = null;
    if (!file) return;
  }
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleMoreFeatureChat = () => {

    setShowMoreFeature(!showMoreFeature);
  }
  const handleChatDownload = () => {
    console.log("Download Hits", chatHistory);
    const doc = new jsPDF();

    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10; // Left and right margin
    const maxLineWidth = pageWidth - margin * 2; // Width available for text
    const lineHeight = 10; // Height for each line
    let currentY = margin; // Initial Y position for the first line

    doc.setFontSize(12);
    doc.text('CHAT LOG', margin, currentY);
    currentY += 15; // Leave space after the title

    chatHistory.forEach((item) => {
      if (item.role === 'user' || item.role === 'assistant') {
        // Check for page break
        if (currentY + lineHeight > pageHeight) {
          doc.addPage();
          currentY = margin;
        }

        if (item.role === 'user') {
          // Add User Content
          doc.setFont('helvetica', 'bold');
          doc.text('User:', margin, currentY);
          currentY += lineHeight;

          doc.setFont('helvetica', 'normal');
          const userContentLines = doc.splitTextToSize(item.content, maxLineWidth);
          userContentLines.forEach((line) => {
            if (currentY + lineHeight > pageHeight) {
              doc.addPage();
              currentY = margin;
            }
            doc.text(line, margin, currentY);
            currentY += lineHeight;
          });
          currentY += lineHeight; // Add extra spacing after user content
        } else if (item.role === 'assistant') {
          // Add Assistant Content
          doc.setFont('helvetica', 'bold');
          doc.text('Assistant:', margin, currentY);
          currentY += lineHeight;

          doc.setFont('helvetica', 'normal');
          try {
            const parsedContent = item.content
            let assistantAnswer = parsedContent.answer || "No answer provided"; // Extract answer

            const finalAssistantAnswer = assistantAnswer
            .split("<nl>") // Split the string by "<nl>"
            .filter((item) => item.trim() !== "") // Remove empty strings after splitting
            .map((item) => item.trim()); // Trim and add a period at the end of each point

            const answerLines = doc.splitTextToSize(finalAssistantAnswer, maxLineWidth);
            answerLines.forEach((line) => {
              if (currentY + lineHeight > pageHeight) {
                doc.addPage();
                currentY = margin;
              }
              doc.text(line, margin, currentY);
              currentY += lineHeight;
            });

            // Optionally add page numbers if needed
            if (parsedContent.page_number) {
              if (currentY + lineHeight > pageHeight) {
                doc.addPage();
                currentY = margin;
              }
              doc.text(`Page Number: ${parsedContent.page_number}`, margin, currentY);
              currentY += lineHeight;
            }
          } catch (error) {
            console.error("Error parsing assistant content:", error);
          }

          currentY += lineHeight; // Add extra spacing after assistant content
        }
      }
    });

    const currDate = new Date().toLocaleDateString();
    const currTime = new Date().toLocaleTimeString();

    // Save the generated PDF
    doc.save(`${fileName}_${currDate}_${currTime}.pdf`);
  };

  const singleReponseDownload = (chat) => {
    console.log("Download Hits", chat);

  // Ensure `chat` is a non-empty string
  if (typeof chat !== "string" || chat.trim() === "") {
    console.error("Chat is not a valid string. Ensure the input is a non-empty string.");
    return;
  }

  const doc = new jsPDF();

  const pageHeight = doc.internal.pageSize.getHeight();
  const pageWidth = doc.internal.pageSize.getWidth();
  const margin = 10; // Left and right margin
  const maxLineWidth = pageWidth - margin * 2; // Width available for text
  const lineHeight = 10; // Height for each line
  let currentY = margin; // Initial Y position for the first line

  // Set font and title
  doc.setFontSize(12);
  doc.text("Response", margin, currentY);
  currentY += 15; // Leave space after the title

  // Split the `chat` string into lines using `<nl>`
  const answerLines = chat
    .split("<nl>") // Split the string by `<nl>`
    .filter((item) => item.trim() !== ""); // Remove empty strings

  // Render each line into the PDF
  answerLines.forEach((line) => {
    const wrappedLines = doc.splitTextToSize(line.trim(), maxLineWidth); // Wrap text within margins

    wrappedLines.forEach((wrappedLine) => {
      if (currentY + lineHeight > pageHeight) {
        doc.addPage(); // Add new page if the content exceeds page height
        currentY = margin; // Reset Y position
      }
      doc.text(wrappedLine, margin, currentY);
      currentY += lineHeight;
    });

    // Leave space between points
    currentY += lineHeight;
    if (currentY + lineHeight > pageHeight) {
      doc.addPage();
      currentY = margin;
    }
  });

  // Save the generated PDF
  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  doc.save(`${fileName}_${currDate}_${currTime}.pdf`);
  };
  const wrapHtmlContent = (htmlContent) => {
    return `
          <!DOCTYPE html>
          <html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'>
          <head><meta charset='utf-8'><title>Document</title></head><body>${htmlContent}</body></html>
        `;
  };
  const generateChatPDF = (chatHistory, fileName) => {
    console.log("Download Hits", chatHistory);
    const doc = new jsPDF();

    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10; // Left and right margin
    const maxLineWidth = pageWidth - margin * 2; // Width available for text
    const lineHeight = 10; // Height for each line
    let currentY = margin; // Initial Y position for the first line

    doc.setFontSize(12);
    doc.text('CHAT LOG', margin, currentY);
    currentY += 15; // Leave space after the title

    chatHistory.forEach((item) => {
      if (item.role === 'user' || item.role === 'assistant') {
        // Check for page break
        if (currentY + lineHeight > pageHeight) {
          doc.addPage();
          currentY = margin;
        }

        if (item.role === 'user') {
          // Add User Content
          doc.setFont('helvetica', 'bold');
          doc.text('User:', margin, currentY);
          currentY += lineHeight;

          doc.setFont('helvetica', 'normal');
          const userContentLines = doc.splitTextToSize(item.content, maxLineWidth);
          userContentLines.forEach((line) => {
            if (currentY + lineHeight > pageHeight) {
              doc.addPage();
              currentY = margin;
            }
            doc.text(line, margin, currentY);
            currentY += lineHeight;
          });
          currentY += lineHeight; // Add extra spacing after user content
        } else if (item.role === 'assistant') {
          // Add Assistant Content
          doc.setFont('helvetica', 'bold');
          doc.text('Assistant:', margin, currentY);
          currentY += lineHeight;

          doc.setFont('helvetica', 'normal');
          try {
            const parsedContent = item.content
            let assistantAnswer = parsedContent.answer || "No answer provided"; // Extract answer

            const finalAssistantAnswer = assistantAnswer
            .split("<nl>") // Split the string by "<nl>"
            .filter((item) => item.trim() !== "") // Remove empty strings after splitting
            .map((item) => item.trim()); // Trim and add a period at the end of each point

            const answerLines = doc.splitTextToSize(finalAssistantAnswer, maxLineWidth);
            answerLines.forEach((line) => {
              if (currentY + lineHeight > pageHeight) {
                doc.addPage();
                currentY = margin;
              }
              doc.text(line, margin, currentY);
              currentY += lineHeight;
            });

            // Optionally add page numbers if needed
            if (parsedContent.page_number) {
              if (currentY + lineHeight > pageHeight) {
                doc.addPage();
                currentY = margin;
              }
              doc.text(`Page Number: ${parsedContent.page_number}`, margin, currentY);
              currentY += lineHeight;
            }
          } catch (error) {
            console.error("Error parsing assistant content:", error);
          }

          currentY += lineHeight; // Add extra spacing after assistant content
        }
      }
    });

    // const currDate = new Date().toLocaleDateString();
    // const currTime = new Date().toLocaleTimeString();

    // Save the generated PDF

    return doc.output('blob'); // Return the generated PDF as a Blob
};

const handleDownloadDOC = async () => {
    try {
        console.log("Download Hits", chatHistory);

        // Generate the PDF Blob
        const pdfBlob = generateChatPDF(chatHistory, fileName);
        console.log("Generated PDF Blob:", pdfBlob);

        // Prepare data for API upload
        const apiEndpoint = "https://pdfing.ai:7000/upload-img";
        const formData = new FormData();
        formData.append("file", pdfBlob);
        formData.append("filename", `${fileName}_Chat_History.pdf`);

        // Upload the PDF to the API
        const response = await fetch(apiEndpoint, {
            method: "POST",
            body: formData,
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Upload successful. Response:", data);

        // Set the shareable link
        setShareableLink(data.file_url);
        console.log("Shareable Link:", data.file_url);
    } catch (error) {
        console.error("Error during the process:", error);
    }

    setModalOpen(true);
};

  



  // const handleChatDownload =() =>{
  //   console.log("download Hits", chatHistory);
  //   const doc = new jsPDF();

  //   const pageHeight = doc.internal.pageSize.getHeight();
  //   const pageWidth = doc.internal.pageSize.getWidth();
  //   const margin = 10;
  //   const maxLineWidth = pageWidth - margin * 2 - 20;

  //   doc.setFontSize(12);
  //   doc.text('CHAT', margin, 10);
  //   let currentY = 20; // Start position for the first entry


  //   // Add each object to the PDF
  //   chatHistory.forEach((item, index) => {
  //     if(item.role && item.role!==""){

  //     // Check for page break before adding new content
  //     if (currentY + 20 > pageHeight) { // 20 is for the title height and spacing
  //       doc.addPage();
  //       currentY = 10; // Reset Y position for the new page
  //       doc.text('User Information', margin, currentY);
  //       currentY += 10; // Adjust after the title on the new page
  //     }

  //     // Make role bold
  //     doc.setFont('helvetica', 'bold');
  //     const roleText = `${item.role}:`;
  //     doc.text(roleText, margin, currentY);

  //     // Reset font to normal for content
  //     doc.setFont('helvetica', 'normal');
  //     const details = `${item.content}`;

  //     // Shift details to the right
  //     const detailsX = margin + 20; // Adjust this value to shift right

  //     // Split and get the lines for details
  //     const detailsLines = doc.splitTextToSize(details, maxLineWidth);

  //     // Add details text, adjusting Y position
  //     detailsLines.forEach((line, lineIndex) => {
  //       if (currentY + 6 + (lineIndex * 10) > pageHeight) { // Check if it fits on the current page
  //         doc.addPage();
  //         currentY = 10; // Reset Y position for the new page
  //         doc.text('User Information', margin, currentY);
  //         currentY += 10; // Adjust after the title on the new page
  //       }
  //       doc.text(line, detailsX, currentY + 6 + (lineIndex * 10)); // 10 is line height
  //     });

  //     // Update currentY to the end of the last detail line
  //     currentY += 6 + (detailsLines.length * 10) + 10; // Adjust for padding between entries

  //       // let text = `: `

  //       // doc.text(doc.splitTextToSize(text, maxLineWidth), margin, 20 + (index * 20));

  //     }
  //   });
  //   const currDate = new Date().toLocaleDateString();
  //   const currTime = new Date().toLocaleTimeString();

  //   // Save the generated PDF
  //   doc.save(`${fileName}_chatlog_${currDate}_${currTime}.pdf`);
  // }




  const fileapicall = async (text) => {
    const API_URL = 'https://pdfing.ai:7000/makeAnswer';
    setUserMessage("");
    // setChatHistory([...chatHistory, { "role": "user", "content": chatInput }])
    console.log("FileContent:----- Calling API", fileContent)

    // if (!chatInput) {
    //   return;
    // }


    try {
      setIsloading(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },


        body: JSON.stringify({
          // "query": chatInput,
          "websitelist": userWebUrls,
          "websearch": websearch ? "YES" : "NO",
          "assignmenttext": text,
          "context": fileContent,
          // "history": chatHistory ? chatHistory : []

        }),
      };



      const response = await fetch(API_URL, requestOptions);
      console.log("response questions33d", response)

      // const data = await response.json();

      // console.log("data3", data.content)
      // const parsedData = JSON.parse(data.content)
      // console.log("data2",parsedData.answer);
      let data;
      if (websearch) {

        const ans = await response.json();
        data = ans


      } else {
        data = await response.json();
      }
      // setResponse(data);
      console.log("response for questions:", data)
      setChatHistory([...chatHistory, ...data])
      setIsloading(false);

    } catch (e) {

      console.log("some error occured", e);
      setIsloading(false);

    }

  }




  const apicall = async () => {
    let API_URL = ""
    if (websearchonly === true) {
      API_URL = 'https://pdfing.ai:7000/askonlyinternet'
    }
    else {
      if (isSelectedMath) {
        API_URL = 'https://pdfing.ai:7000/mathschat'
      }
      else {
        API_URL = 'https://pdfing.ai:7000/pdfchat';
      }
    }
    setUserMessage("");
    setChatHistory([...chatHistory, { "role": "user", "content": chatInput }])
    console.log("FileContent:-----", fileContent)

    if (!chatInput) {
      return;
    }

    let body = {
      "query": chatInput,
      "websitelist": userWebUrls,
      "websearch": websearch ? "YES" : "NO",
      // "assignmenttext":userUploadedContent,
      "context": fileContent,
      "history": chatHistory ? chatHistory : []

    }
    if (websearchonly === true) {
      body = {
        "query": chatInput
      }
    }
    if (isSelectedMath === true) {
      body = {
        "query": chatInput,
        "context": fileContent,
      }
    }


    try {
      setIsloading(true);
      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },


        body: JSON.stringify(body),
      };



      const response = await fetch(API_URL, requestOptions);
      console.log(response)
      // const data = await response.json();

      // console.log("data3", data.content)
      // const parsedData = JSON.parse(data.content)
      // console.log("data2",parsedData.answer);

      let data;

      data = await response.json();
      // if (websearchonly === true) {
      //   const ans = await response.json();
      //   console.log(ans)
      //   //const parsedResponse = JSON.parse(ans);
      //   //console.log("response from web only:", parsedResponse)
      //   data = ans
      // }
      // else if (websearch) {

      //   const ans = await response.json();
      //   data = ans


      // } else {
      //   data = await response.json();
      // }
      console.log("99", data)
      // let newContent = ""
      // for (let i = 0; i < data.content.length; i++) {
      //   if ((data.content[i] == '\\' && data.content[i + 1] == "n") || (data.content[i] == "\n")) {
      //     console.log(data.content[i], "found22")
      //     // data.content[i] = ''
      //     // data.content[i+1] = ''
      //     // i = i +1
      //   } else {
      //     newContent = newContent + data.content[i]
      //   }

      // }
      // console.log("Cleandata", newContent)
      //data.content = newContent
      setResponse2(data);
      setChatHistory([...chatHistory, { "role": "user", "content": chatInput }, data])
      setIsloading(false);

    } catch (e) {

      console.log("some error occured", e);
      setIsloading(false);

    }

  }

  const openFileToPage = (pageNum) => {
    const pdfPageUrl = `${fileUrl}#page=${pageNum}`;
    // Open the PDF in a new tab
    window.open(pdfPageUrl, '_blank',
      'noopener,noreferrer,width=700,height=820,scrollbars=yes,resizable=yes'
    );
  }

  const [websearchonly, setWebsearchonly] = useState(false);

  console.log("chat history", chatHistory)

  return (
    <div>

      <div className="chatbot" style={{ backgroundColor: 'white', width: "35%" }}>

        <header ref={headerRef} style={{ backgroundColor: '#0066cc' }}>
          <h2>Chat with {fileName ? fileName : "document"}</h2>
          <div style={{
            display: "flex",
            // alignContent:"center",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"

          }}>
            <div style={{ cursor: "pointer", display: "flex", flexDirection: "row" }}>
              <div>
                <h6>Search Type</h6>
              </div>
              <label style={{ marginLeft: "10px" }}>
                <input
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                  type="radio"
                  name="websearch"
                  value="on"
                  checked={(websearch === false && websearchonly === false)}
                  onChange={() => {
                    setWebsearch(false);
                    setWebsearchonly(false);
                    setUserWebUrls([]);
                  }}
                />
                Document only
              </label>
              <label>
                <input
                  type="radio"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                  name="websearch"
                  value="off"
                  checked={websearch === true}
                  onChange={() => {
                    setWebsearch(true);
                    setWebsearchonly(false);
                    setUserWebUrls([]);
                  }}
                />
                Web and document
              </label>

              <label>
                <input
                  type="radio"
                  style={{ marginRight: "5px", marginLeft: "5px" }}
                  name="websearch"
                  value="off"
                  checked={websearchonly === true}
                  onChange={() => {
                    setWebsearch(false);
                    setWebsearchonly(true);
                    setUserWebUrls([]);
                  }}
                />
                Web only
              </label>
            </div>

            <div className="url_list">
              {(websearch || websearchonly) && <textarea
                style={{
                  border: 'none',
                  borderBottom: '2px solid #000',
                  outline: 'none',
                  padding: 0,
                  marginLeft: "2em",
                  height: '1.5em',
                  width: '20em'

                }}
                value={userWebUrls}
                placeholder="Enter urls of websites"
                spellCheck={false}
                onChange={handleInputUrlsChange}
                onKeyDown={handleKeyUrlDown}
              ></textarea>}
            </div>
            {/* <div>{websearch && userWebUrls}</div> */}
          </div>

          <span className="close-btn material-symbols-outlined" onClick={() => document.body.classList.remove('show-chatbot')}>
            close
          </span>
        </header>

        <ul className="chatbox" ref={chatboxRef}>
          {chatHistory.length === 1 && (
            <li className="chat incoming">
              <p>
                Welcome to <u style={{ color: 'red' }}>Pdfing.ai</u> You can chat with the selected pdf here.
                <br />
                <br />

                {!fileName && " Please upload a pdf to start chatting."}

              </p>

            </li>
          )}




          {chatHistory.length > 0 && chatHistory.map((message, index) => {
            let ans, answer, page, pageNo;
            console.log("Recieved message content ", message)

            if (message.role == "assistant") {
              answer = message.content.answer;
              page = message.content.page_number
              pageNo = parseInt(page.split(/[,|-]/)[0], 10);
            }
            else {
              answer = message.content
            }





            // try {
            //   try {
            //     console.log("parsing message.content", message.content)
            //     ans = JSON.parse(message.content)
            //     console.log("ans", ans)
            //   }
            //   catch {
            //     ans = JSON.parse(message.content.slice(7, -3).trim())
            //   }
            //   answer = ans.answer
            //   console.log("ans:", ans)
            //   page = ans.page_number
            //   pageNo = parseInt(page.split(/[,|-]/)[0], 10);

            //   // ans = "hi"
            //   // ans = ans.slice(7, -3).trim();
            // } catch (e) {
            //   console.log("message.content33", e)
            //   answer = message.content
            // }


            return (

              message.content != "" && (
                <li key={index} className={`chat ${message.role}`}>

                  {message.role === 'user' ? (
                    <div style={{
                      maxWidth: "55%",
                      padding: "10px",
                      borderRadius: "12px", backgroundColor: "#D7BDE2"
                    }}>{message.content}</div>
                  ) : (
                    message &&
                    <>
                      <span style={{
                        color: "white",
                        backgroundColor: "#2980B9",
                        padding: "5px",
                        width: "fit-content",
                        height: "auto",
                        textAlign: "center",
                      }}>PDFing</span>


                      <Card
                        ref={cardRef}
                        sx={{
                          width: "75%",
                          marginTop: 2,
                          backgroundColor: "#D6EAF8",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: 8,
                          padding: 3,
                        }}
                      >
                        <div>
                          <strong>Answer:</strong>
                          <ul>
                            {answer
                              .split("<nl>") // Split the string by ". "
                              .filter((item) => item.trim() !== "") // Remove empty strings after splitting
                              .map((item, index) => (
                                <li key={index}>{item.trim()}</li> // Add a period at the end of each point
                              ))}
                          </ul>
                        </div>
                        <br />
                        {page && (
                          <div style={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between"
                          }}>
                          <strong
                            style={{
                              color: "#0066cc",
                              cursor: "pointer",
                            }}
                            onClick={() => openFileToPage(pageNo)}
                          >
                            Page: {page}
                          </strong>
                          <Download onClick={() => singleReponseDownload(answer)}/>
                          
                          </div>
                        )}
                      </Card>

                    </>
                  )}
                </li>
              )
            )
          })}
        </ul>


        <div className="chat-input">

          {isLoading && <div style={{ marginLeft: "30px" }} >Loading...</div>

          }


          <div style={{ width: "100%", display: "flex", justifyContent: "space-between", marginLeft: "10px" }}>

            <div style={{
              position: "absolute",
              right: "10px",
              bottom: "90px",
              backgroundColor: "#257cda",
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              height: "40px",
              width: "40px",
              borderRadius: "60%",
              cursor: "pointer"
            }} onClick={handleChatDownload}>
              <DownloadOutlinedIcon />
            </div>
            <div style={{
              position: "absolute",
              right: "60px",
              bottom: "90px",
              backgroundColor: "#257cda",
              display: "flex",

              alignItems: "center",
              justifyContent: "center",
              color: "#fff",
              height: "40px",
              width: "40px",
              borderRadius: "60%",
              cursor: "pointer"
            }} onClick={() => handleDownloadDOC()}><Share2Icon /></div>
            




          </div>

          <div style={{ display: "flex", borderTop: "1px solid #ddd" }}>
            {/* <div style={{
              display:"flex",
           
              alignItems:"center",
              justifyContent:"center",
              color:"#000",
              marginRight:"1em",
              cursor:"pointer"
            }} onClick={handleMoreFeatureChat}>
              { showMoreFeature ? <CloseOutlined/> : <AddCircleOutline/>}
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#FFF",
                cursor: "pointer",
                backgroundColor: "#257CDA",
                // height: "4rem",
                width: "4rem",
                borderRadius: "50%"
              }}
              onClick={() => fileInputRef.current && fileInputRef.current.click()} // Trigger the file input click
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                accept={mapSearch ? ".pdf,.jpg" : ".pdf"}
                style={{ display: "none" }}
              />
              <AttachFile />
            </div>


            <textarea
              value={userMessage}
              placeholder="Enter a message..."
              spellCheck={false}
              ref={chatInputRef}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
            ></textarea>

            <div style={{
              position: "relative",
              left: "-22px",
              bottom: "-1px",
              // border:"1px solid #000",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",

            }}>
              {/* <div style={{
                display:"flex",
                // alignContent:"center",
                alignItems:"center",
                justifyContent:"center",
                
              }}>
                <div>Map</div>
                <div style={{
                
                cursor:"pointer"
              }}>
                  <Switch 
                    checked={mapSearch} 
                    onChange={() => {
                      setMapSearch(!mapSearch);
                      // setUserWebUrls([]);
                    }} 
                  />
                </div>
              </div> */}

              <div onKeyDown={handleKeyDown} style={{
                display: "flex",
                // alignContent:"center",
                alignItems: "center",
                justifyContent: "center",
                gap: "12px",
                cursor: "pointer",
                // width: "4rem"
              }}>
                <div><AudioRecorder chatInputRef={chatInputRef} setChatInput={setChatInput} setUserMessage={setUserMessage} /></div>
              </div>
            </div>

            <span id="send-btn" className="material-symbols-rounded" style={{ display: "flex", alignItems: "center" }} onClick={apicall}>
              <i style={{ fontSize: "25px" }} class="ri-arrow-right-circle-line"></i>
            </span>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SummaryChatbot
