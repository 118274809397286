import { useState } from 'react'
import AddItemForm from './AddItemForm'
import QuestionList from './QuestionList'
import styles from './TopicList.module.css'

export default function TopicList({setTopicFromList,onEditQuestion, topics, onDeleteTopic, onAddQuestion, onDeleteQuestion, onEditTopic,handleFileUpload3 }) {
  const [expandedTopic, setExpandedTopic] = useState(null)
  const [editingTopic, setEditingTopic] = useState(null)

  const toggleTopic = (index) => {
    setExpandedTopic(expandedTopic === index ? null : index)
  }

  const startEditingTopic = (index) => {
    setEditingTopic(index)
  }

  const handleEditTopic = (index, newName) => {
    onEditTopic(index, newName)
    setEditingTopic(null)
  }

  return (
    <div className={styles.topicList}>
      {topics.map((topic, index) => (
        <div key={index} className={styles.topicCard}>
          <div className={styles.topicHeader}>
            {editingTopic === index ? (
              <AddItemForm
                onAdd={(newName) => handleEditTopic(index, newName)}
                placeholder="Edit topic name"
                initialValue={topic.topic}
              />
            ) : (
              <div className={styles.topicName} onClick={() => toggleTopic(index)}>
                {/* {topic.topic} {" (TopicIndex: " + index + " & TopicId: " + topic.topic_id+")"} */}
                {topic.topic} 
              </div>
            )}

            <div className={styles.topicActions}>
            <div
              style={{
                borderRadius: "12px",
                backgroundColor: "#40a9ff",
                padding: "3px 6px",
                color: "#fff",
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <label htmlFor={`file-upload-${index}`} style={{ cursor: "pointer" }}>
                Upload File
              </label>
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={(e) => handleFileUpload3(e, index)}
                className={styles.fileInput}
                id={`file-upload-${index}`} // Unique id for each input
                style={{ display: "none" }} // Hides the input
              />
            </div>
              
            <button className={styles.actionButton} onClick={() => toggleTopic(index)}>
                {expandedTopic === index ? 'Hide' : 'Add questions'}
              </button>
              <button className={styles.actionButton} onClick={() => startEditingTopic(index)}>
                Edit
              </button>
              <button className={styles.actionButton} onClick={() => setTopicFromList(topic.questions)}>
                Add to Comparision
              </button>
              <button className={styles.actionButton} onClick={() => onDeleteTopic(index)}>
                Delete
              </button>
            </div>
          </div>
          {expandedTopic === index && (
            <div className={styles.topicContent}>
              <AddItemForm onAdd={(question) => onAddQuestion(index, question)} placeholder="Add a new question" />
              <QuestionList onEditQuestion={onEditQuestion} topicIndex={index} questions={topic.questions} onDeleteQuestion={(questionIndex) => onDeleteQuestion(index, questionIndex)} />
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

