import { useState } from 'react';
import Navbar from '../Navbar/Navbar';
import styles from './Homework.module.css';
import cloudImg from '../../assets/clodImg.svg'
import generateIcon from '../../assets/Vector-2.svg'
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

export default function HomeworkPage({isHomeWork,setIsHomeWork,showMasterListModal,setShowMasterListModal,setIsAuthenticated,
  setFileModal,
  fileModal,

  setHasMath,
  hasMath,
  setUserFile,
  file,
  setFile,
  fileUrl,
  setFileUrl,
  userFile,
  sourceFileName,
  setSourceFileName,
  assigFileName,
  setAssigFileName,
  fileName,
  setFileName,
  useOCR,
  setUseOCR
}) {
  const data = "Try for free"
  const [showUploadoption, setUploadoption] = useState(false)
  const { isAuthenticated, loginWithRedirect, loginWithPopup } = useAuth0();
  const navigate = useNavigate();

  return (
    <>
      {/* <Navbar showMasterListModal={showMasterListModal} setShowMasterListModal={setShowMasterListModal} isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} data={data} setFileModal={setFileModal}
        fileModal={fileModal} /> */}
      <div className={styles.container}>
        <div className={styles.content}>
          <h1 className={styles.title}>
            Your Personal AI<br />
            Homework Assistant
          </h1>
          <p className={styles.subtitle}>
            Instantly find solutions to your assignments with a simple upload—<br />
            math, science, or anything!
          </p>

          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.stepNumber}>1</div>
              <div className={styles.card}>
                <h2>Upload</h2>
                <p>Select your source file and
                your assignment file</p>
                <div className={styles.uploadIcon}>
                  <div className={styles.imgcloudIcon}><img src={cloudImg} /></div>
                </div>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>2</div>
              <div className={styles.card}>
                <h2>Customise</h2>
                {/* <p>Sort summaries,<br />enable Math Mode,<br />and ask AI questions.</p> */}
                <p>Modify source summary and solve<br /> math problems</p>
                <div className={styles.radioGroup}>
                  <div className={styles.radioOption}>
                    <span className={styles.radioLabel}>Summary Type</span>
                    <div className={styles.radioButtons}>
                      <label className={styles.radio}>
                        <input type="radio" name="type" defaultChecked />
                        <span>TOPIC</span>
                      </label>
                      <label className={styles.radio}>
                        <input type="radio" name="type" />
                        <span>PAGE</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.step}>
              <div className={styles.stepNumber}>3</div>
              <div className={styles.card}>
                <h2>Generate</h2>
                {/* <p>Generate your<br />homework and chat<br />with your document</p> */}
                <p>Check your work and chat with <br /> your source document</p>
                <div className={styles.uploadIcon}>
                  <div className={styles.imggenerateIcon}><img src={generateIcon} /></div>
                  <p className={styles.generateText}>GENERATE</p>
                </div>
              </div>
            </div>
          </div>

          <button onClick={() => {
            setIsHomeWork(true)
            navigate("/check-homeworks");
          }} className={styles.checkButton}>
            Check Your Work Now
          </button>
        </div>
      </div>
    </>
  )
}