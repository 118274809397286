import React from 'react';
import styles from './PricingModal.module.css';
import { useNavigate } from 'react-router-dom';

const PricingModal = ({isPremium,setIsPremium}) => {
    const navigate = useNavigate();

    const PaymentProcess = async () => {

      const apiEndpoint = "http://127.0.0.1:7000/create-checkout-session";
  
      try {
          const response = await fetch(apiEndpoint, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  "lookup_key": "plan_A",
              }),
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          // Parse the JSON response
          const data = await response.json();
          console.log(data, "Response Data");
  
          // Access the checkout_url
          console.log(data.checkout_url, "Checkout URL");
          window.location.href = data.checkout_url;
      } catch (error) {
          console.error("Error during payment process:", error);
      }
  };
  



  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button onClick={()=>setIsPremium(!isPremium)} className={styles.closeButton}>×</button>
        
        <div className={styles.modalContent}>
          <h3 className={styles.subtitle}>You've Run Out of Words</h3>
          <h2 className={styles.title}>Choose the right plan for you</h2>
          
          <p className={styles.description}>
            Upgrade your plan to add more storage and keep summarizing your PDFs without limits.
          </p>

          <div className={styles.billingToggle}>
            <button className={`${styles.toggleButton} ${styles.active}`}>Monthly</button>
            <button className={styles.toggleButton}>Yearly</button>
          </div>

          <div className={styles.plansContainer}>
            <div className={styles.planCard}>
              <h3 className={styles.planName}>Student</h3>
              <p className={styles.planSubtitle}>Just getting started</p>
              
              <ul className={styles.featuresList}>
                <li>✓ Feature 1</li>
                <li>✓ Feature 2</li>
                <li>✓ Feature 3</li>
                <li>✓ Feature 4</li>
                <li>✓ Feature 5</li>
              </ul>

              <div className={styles.pricing}>
                <span className={styles.price}>$0</span>
                <span className={styles.period}>/month</span>
              </div>

              <button className={styles.continueButton}>Continue for free</button>
            </div>

            <div className={`${styles.planCard} ${styles.highlighted}`}>
              <h3 className={styles.planName}>Professional</h3>
              <p className={styles.planSubtitle}>You have startups</p>
              
              <ul className={styles.featuresList}>
                <li>✓ Feature 1</li>
                <li>✓ Feature 2</li>
                <li>✓ Feature 3</li>
                <li>✓ Feature 4</li>
                <li>✓ Feature 5</li>
                <li>✓ Feature 6</li>
                <li>✓ Feature 7</li>
              </ul>

              <div className={styles.pricing}>
                <span className={styles.price}>$xx</span>
                <span className={styles.period}>/month</span>
              </div>

              <button onClick={() => PaymentProcess()} className={styles.subscribeButton}>Subscribe</button>
            </div>
          </div>

          <button className={styles.bottomContinueButton}>Continue for free</button>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
