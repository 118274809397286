import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import pdfToText from 'react-pdftotext';
import './FileUpload.css';
import GearGif from "../../GearGif.gif";
import { Flex } from 'antd';
import { CancelOutlined, RemoveCircle, RemoveOutlined } from '@mui/icons-material';
import { useAuth0 } from '@auth0/auth0-react';


const FileUpload = ({selectedTopicInMasterList,SetSelectedTopicInMasterList,setTopicFromList,topicFromList,files, setFiles, fileNames,setFileNames,fileBlobUrls,setFileBlobUrls, fileModal, setFileModal, isLoading2, setIsLoading2, fileTexts,setFileTexts, setTopics}) => {
    const [wordCounts, setWordCounts] = useState({});
    const [useOCR, setUseOCR] = useState([]); // Track whether OCR is enabled for each file
    const navigate = useNavigate();
    const fileInputRef = useRef(null);
    const [one, setOne] = useState(["",""])
    const {user} = useAuth0();
    const [newFileAdded, setNewFileAdded] = useState([]);
    const [topicsMasterlist, setTopicsMasterlist] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMasterList, setSelectedMasterList] = useState(false)

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Trigger the hidden file input
    };
    

    const handleFileChange = (event,index) => {
        const selectedFiles = Array.from(event.target.files);
        console.log(selectedFiles,"select44")

        setNewFileAdded((prevFiles) => [...prevFiles, ...selectedFiles])
        const updatedFileNames = [...one]; // Clone the current fileNames state

        // Loop through selected files and assign them to the next available slot
        selectedFiles.forEach((file) => {
            const nextEmptyIndex = updatedFileNames.findIndex((name) => name === ""); // Find the next empty slot
            if (nextEmptyIndex !== -1) {
                updatedFileNames[nextEmptyIndex] = file.name; // Assign file name to the empty slot
            }
        });

        setOne(updatedFileNames);

        // Update blob URLs
        const newBlobUrls = {};
        selectedFiles.forEach((file) => {
            const blobUrl = URL.createObjectURL(file);
            newBlobUrls[file.name] = blobUrl;
        });
        setFileBlobUrls((prevUrls) => ({
            ...prevUrls,
            ...newBlobUrls, // Merge new blob URLs with previous ones
        }));
    
        // Update the files array
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    
        // Update file names
        setFileNames((prevFileNames) => [
            ...prevFileNames,
            ...selectedFiles.map((file) => file.name),
        ]);
    
        // Update useOCR state
        setUseOCR((prevUseOCR) => [
            ...prevUseOCR,
            ...new Array(selectedFiles.length).fill(false),
        ]);
    };
    

    const handleRemoveFile = (index,file) => {
        // Get the file name to remove
    const fileNameToRemove = file.name;

    // Retrieve the current fileTexts object from localStorage (if it's stored in localStorage)
    const existingData = localStorage.getItem('fileTexts');
    const textObj = existingData ? JSON.parse(existingData) : {}; // Parse data if it exists

    // Check if fileNameToRemove exists in the textObj and delete it
    if (textObj.hasOwnProperty(fileNameToRemove)) {
        delete textObj[fileNameToRemove]; // Remove the key-value pair
        console.log(`Removed file: ${fileNameToRemove}`);
        
        // Optionally, you can update localStorage with the new textObj without the removed file
        localStorage.setItem('fileTexts', JSON.stringify(textObj));
    } else {
        console.error(`File ${fileNameToRemove} not found in textObj`);
    }
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);

        const newUseOCR = [...useOCR];
        newUseOCR.splice(index, 1);
        setUseOCR(newUseOCR); // Update OCR state when a file is removed

        const newFileNames = [...fileNames];
        newFileNames.splice(index, 1);
        setFileNames(newFileNames);
    };

    const toggleOCR = (index) => {
        const updatedUseOCR = [...useOCR];
        updatedUseOCR[index] = !updatedUseOCR[index];
        setUseOCR(updatedUseOCR); // Toggle OCR for the selected file
    };

    const countWords = (text) => {
        return text.split(/\s+/).length;
    };

    const callOverviewOnce = (text, wordCount) => {
        console.log("Text processed:", text);
        console.log("Word count:", wordCount);
    };
    const splitIntoTopics = (response) => {
        const topicRegex = /(<Topic\d+(\.\d+)*>[\s\S]*?)(?=<Topic\d+(\.\d+)*>|$)/g;
        const topicsArray = [];
        let match;
        while ((match = topicRegex.exec(response)) !== null) {
            topicsArray.push(match[1].trim());
        }
        return topicsArray;
    };
      const handleOpenFile = (file) => {
        window.open(URL.createObjectURL(file), '_blank');
      };

    const handleProcessFiles = async () => {
        setFileModal(!fileModal);
        setIsLoading2(true);
        console.log("Processing files started...",newFileAdded);
        navigate('/comparison');

        try {
            // Process each file - either OCR or direct parsing
            const textPromises = newFileAdded.map(async (file, index) => {
                try {
                    let text = '';
                    if (useOCR[index]) {
                        // OCR processing for the file
                        console.log(`Processing ${file.name} with OCR...`);
                        const formData = new FormData();
                        formData.append('pdfFile', file);

                        const response = await fetch('https://pdfing.ai:7000/ocr', {
                            method: 'POST',
                            body: formData,
                        });

                        if (response.ok) {
                            const ocrResult = await response.json(); // Assuming OCR API returns JSON
                            text = ocrResult.content || ''; // Adjust based on actual API response structure
                            console.log(`OCR returned for ${file.name}:`, text);

                            // Check if OCR text is valid
                            if (!text) {
                                console.error(`OCR returned empty text for ${file.name}`);
                            }
                        } else {
                            console.error(`OCR failed for ${file.name}:`, response.statusText);
                            throw new Error(`OCR failed for ${file.name}`);
                        }
                    } else {
                        // Direct parsing for the file
                        console.log(`Processing ${file.name} with direct parsing...`);
                        const filename = `${Date.now()}_${file.name}`
                        text = await pdfToText(file);
                        console.log(`Direct parsing returned for ${file.name}:`, text);
                    }

                    // Ensure text is processed and returned
                    return { [file.name]: text };
                } catch (error) {
                    console.error(`Error processing ${file.name}:`, error);
                    throw error; // Ensure errors are propagated
                }
            });
            const existingData = localStorage.getItem('fileTexts');
            let updatedData = {};
            
            if (newFileAdded.length === 0) {
                setIsLoading2(false);
                return;
            }

            // Parse existing data if available and valid
            if (existingData) {
                try {
                    updatedData = JSON.parse(existingData); // Convert JSON string back to an object
                    
                } catch (error) {
                    console.error("Error parsing existing fileTexts from localStorage:", error);
                }
            }

            // Wait until all files are processed
            console.log("Waiting for all files to be processed...");
            const texts = await Promise.all(textPromises);
            console.log(updatedData,"here33")
            // Combine results into a single object
            const textObj = texts.reduce((acc, curr) => ({ ...acc, ...curr }), {});
            updatedData = { ...updatedData, ...textObj }; 

            if (Object.keys(textObj).length > 0) {
                console.log("All files processed successfully, textObj:", textObj,selectedMasterList);

                // Now calling the depose API
                if (selectedTopicInMasterList){
                    console.log("Storing processed fileTexts and topics in localStorage...");
                    localStorage.setItem('fileTexts', JSON.stringify(updatedData));
                    
                    const topics = [];

                    // Ensure topicFromList is an array before using map
                    if (Array.isArray(topicFromList)) {
                        topicFromList.map((item) => {
                            if (item.question && item.question.trim() !== "") {
                                topics.push(item.question);
                            }
                        });
                    } else {
                        console.error("Error: topicFromList is not an array.", topicFromList);
                    }

                    console.log("Topics:44", topics);

                    // Format topics with <Topic1> tags and include numbering
                    var formattedTopics = topics.map((topic, index) => `<Topic1>${index + 1}. ${topic}</Topic1>`).join(" ");

                    // Store the formatted string in localStorage
                    localStorage.setItem('topics', formattedTopics);

                    console.log(formattedTopics,"999");

                    setIsLoading2(false);
                }else{
                    const currentUrl = window.location.href;

                    // Check if the URL matches the specific paths
                    if (currentUrl.includes("/comparison")) {
                        const existingTopic = localStorage.getItem("topics");
                        console.log("Existing Topic:", existingTopic);

                        if (existingTopic) {
                            setIsLoading2(false);
                            localStorage.setItem('fileTexts', JSON.stringify(updatedData));
                            return;
                        }
                    }
                    console.log("Calling depose API...");
                const deposeResponse = await fetch('https://pdfing.ai:7000/depose', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'topicgenerate',
                        main: textObj,
                    }),
                });

                if (deposeResponse.ok) {
                    const topics = await deposeResponse.text();
                    console.log("Depose API returned topics:", topics);

                    // Saving data to localStorage (verify textObj is correct)
                    console.log("Storing processed fileTexts and topics in localStorage...");
                    localStorage.setItem('fileTexts', JSON.stringify(updatedData));
                    localStorage.setItem('topics', topics);
                    console.log( topics," topicFromList")
                    console.log(typeof(topics))

                    setIsLoading2(false);

                    // Navigating to comparison
                    // console.log("Navigating to comparison page...");
                    // navigate('/comparison');
                } else {
                    console.error("Depose API call failed:", deposeResponse.statusText);
                }
                }
            } else {
                if (files && selectedMasterList){
                    const topics = [];
                    console.log(topicFromList,"lisr444")

                    // Ensure topicFromList is an array before using map
                    if (Array.isArray(topicFromList)) {
                        topicFromList.map((item) => {
                            if (item.question && item.question.trim() !== "") {
                                topics.push(item.question);
                            }
                        });
                    } else {
                        console.error("Error: topicFromList is not an array.", topicFromList);
                    }

                    console.log("Topics:44", topics);

                    // Format topics with <Topic1> tags and include numbering
                    var formattedTopics = topics.map((topic, index) => `<Topic1>${index + 1}. ${topic}</Topic1>`).join(" ");

                    // Store the formatted string in localStorage
                    localStorage.setItem('topics', formattedTopics);

                    console.log(formattedTopics,"999");

                    setIsLoading2(false);
                }
            }
        } catch (error) {
            console.error("Error in handleProcessFiles:", error);
        }
    };
    const fetchMasterlist = async (email) => {
        const apiLink = `https://pdfing.ai:7000/masterlist/${email}`;
      
        try {
          // Make the GET request
          const response = await fetch(apiLink, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
      
          if (!response.ok) {
            throw new Error(`Error fetching masterlist: ${response.statusText}`);
          }
      
          // Parse the JSON response
          const masterlist = await response.json();
          console.log("Masterlist retrieved:", masterlist);
      
          // Assuming you want to set this data into the topics state
          if (masterlist.topics) {
            console.log(masterlist.topics,"useeffect masterlist")
            setTopicsMasterlist(masterlist.topics);
          }
        } catch (error) {
          console.error("Error retrieving masterlist:", error);
        }
      };

    useEffect(() => {
        if (files && files.length > 0) {
            const updatedFileNames = [...one]; // Clone the current state of `one`
    
            // Loop through the files array and add file names if not already present
            files.forEach((file) => {
                // Check if the file name already exists in the state
                if (!updatedFileNames.includes(file.name)) {
                    const nextEmptyIndex = updatedFileNames.findIndex((name) => name === ""); // Find the next empty slot
                    if (nextEmptyIndex !== -1) {
                        updatedFileNames[nextEmptyIndex] = file.name; // Assign the file name to the empty slot
                    }
                }
            });
    
            setOne(updatedFileNames); // Update the state with the new file names
        }
    }, [files]);
    useEffect(() => {
        fetchMasterlist(user?.email)
        // if(topicFromList){
        //     setSelectedMasterList(true)
        // }
        console.log(files,"fileass")
    }, []);

    return (

        <div className="file-upload">
            <>
            <div style={{
                    position: "relative",
                    }}>
                    <div><h2>Select Files</h2></div>
                    <div><div style={{
                    position: "absolute",
                    top:'0',
                    right:'1%',
                    
                }} className="modal-close" onClick={() => setFileModal(!fileModal)}>×</div></div>
            </div>
            <div className="file-upload-container">
            
            {files.length > 2 ? 
            <div className="file-names">
            {files.map((file, index) => (
  <label className="file-upload-box" key={index}>
    <div className="file-upload-box3">
      <div>{`File ${index + 1}`}</div>

      {/* Display the file name or its truncated version if too long */}
      <div>{file.name && file.name.length > 24 ? `${file.name.substring(0, 24)}...` : file.name}</div>
      
      {/* Show "No file chosen" if file name is not present */}
      {!file.name && <div>No file chosen</div>}

      <div className="file-names-selectfile">Select files</div>
      
      <label>
        <input
          type="checkbox"
          checked={useOCR[index]} // Ensure `useOCR` is properly initialized
          onChange={() => toggleOCR(index)} // Ensure `toggleOCR` is correctly implemented
        />
        Use OCR
      </label>

      <div className="remove-btn" onClick={() => handleRemoveFile(index,file)}>
        <CancelOutlined />
      </div>
    </div>
  </label>
))}


            </div>
         : one.map((name, index) => (
                <label key={index} className="file-upload-box">
                    <input
                        type="file"
                        onChange={(e) => handleFileChange(e, index)}
                        style={{ display: "none" }}
                    />
                    <div className="file-upload-box3">
                        <div>{`File ${index + 1}`}</div>
                        <div>{name.length > 24 ? name.substring(0, 24) :name || "No file chosen"}</div>
                        <button
                            className="file-names-selectfile"
                            onClick={(e) => e.target.previousSibling.click()}
                        >
                            Select File
                        </button>
                        {name != "" &&<label>
                        <input
                            type="checkbox"
                            checked={useOCR[index]}
                            onChange={() => toggleOCR(index)}
                        />
                        Use OCR
                        </label>}
                    </div>
                </label>
            ))}

            </div>
            {/* <ul>
                {files.map((file, index) => (
                    <li key={index}>
                        {file.name}
                        <button className="remove-btn" onClick={() => handleRemoveFile(index)}>Remove</button>
                        <label>
                            <input
                                type="checkbox"
                                checked={useOCR[index]}
                                onChange={() => toggleOCR(index)}
                            />
                            Use OCR
                        </label>
                    </li>
                ))}
            </ul> */}
            <div style={{
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'space-between'

                }}>
            {files.length > 0 ? (
            <button onClick={handleProcessFiles}>Process Files</button>
            ) : (
            <div></div>
            )}
            <div>
            <button
            onClick={handleButtonClick}
                
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                }}
            >
                Add File
            </button>
            <button
            onClick={openModal}
                
                style={{
                    padding: "10px 20px",
                    backgroundColor: "#007BFF",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                    marginLeft:"10rem"
                }}
            >
                {selectedTopicInMasterList || "Master List"}
            </button>

            <input
                type="file"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
                style={{ display: "none" }}
            />
            </div>
            {isModalOpen &&<div className="container-modal">

                    <div className="closeButton" onClick={closeModal}>
                    &times;
                    </div>
                    <ul className="topicList">
                    {topicsMasterlist.map((topic, index) => (
                        <li
                        key={topic.topic_id}
                        className={`topicItem ${
                            selectedTopicInMasterList === topic.topic ? "selected" : ""
                        }`}onClick={() => {
                            // setTopicFromList([])
                            SetSelectedTopicInMasterList(topic.topic)
                            
                            if (Array.isArray(topic.questions)) {
                                setTopicFromList(topic.questions);
                            } else {
                                console.error("Invalid data assigned to topicFromList:", topic.questions);
                            }
                            setSelectedMasterList(true)
                            console.log(selectedTopicInMasterList === topic.topic,selectedTopicInMasterList, topic.topic)
                            closeModal()
                        }}>
                        {topic.topic}
                        </li>
                    ))}
                    </ul>
            </div>}
            </div>
            </>
            
        </div>
    );
};

export default FileUpload;
